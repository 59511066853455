import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import ContactForm from "./ContactForm"
import { Portal } from "@material-ui/core"

export const query = graphql`
  {
    prismic {
      allContact_forms {
        edges {
          node {
            title
            description
            inquiry_types {
              inquiry_type_label
              inquiry_type_value
            }
          }
        }
      }
    }
  }
`

const renderComponent = ({ onClose }) => data => {
  const doc = data.prismic.allContact_forms.edges.slice(0, 1).pop()
  if (!doc) return null

  const inquiryOptions = doc.node.inquiry_types.map(it => ({
    label: it.inquiry_type_label[0].text,
    value: it.inquiry_type_value[0].text,
  }))

  const container = document && document.getElementById("form-portal")

  return (
    <Portal container={container}>
      <ContactForm
        onClose={onClose}
        title={doc.node.title[0].text}
        description={doc.node.description[0].text}
        inquiryOptions={inquiryOptions}
      />
    </Portal>
  )
}

export default props => {
  return (
    <>
      <StaticQuery
        query={query}
        render={withPreview(renderComponent(props), query)}
      />
    </>
  )
}
