import styled from "styled-components"

const CallToAction = styled("div")`
  background: #efefef;
  display: flex;
  justify-content: center;
  padding: 0 1rem;

  .inner-container {
    max-width: 1200px;
    flex: 0 1 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  .message {
    p {
      font-size: 1.25rem;
      margin: 2rem 0;
      font-weight: 700;
    }
  }

  .action-button {
    @media screen and (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
`

export default CallToAction
