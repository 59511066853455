import React, { useState, useCallback } from "react"
import CallToAction from "../components/CallToAction"
import ActionButton from "../components/ActionButton"
import ContactFormContainer from "./ContactFormContainer"

const CallToActionContact = () => {
  const [showContactForm, setShowContactForm] = useState(false)

  const handleClose = useCallback(() => setShowContactForm(false), [
    setShowContactForm,
  ])
  return (
    <>
      <CallToAction>
        <div className="inner-container">
          <div className="message">
            <p>Ready to get started?</p>
          </div>
          <div className="action-button">
            <ActionButton onClick={() => setShowContactForm(true)}>
              Get in touch
            </ActionButton>
          </div>
        </div>
      </CallToAction>
      {showContactForm && <ContactFormContainer onClose={handleClose} />}
    </>
  )
}

export default CallToActionContact
