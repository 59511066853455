import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import RelatedArticles from "../components/RelatedArticles"
import { format } from "date-fns"
import CallToActionContact from "../components/CallToActionContact"

export const query = graphql`
  query NewsQuery($uid: String) {
    prismic {
      allNewss(uid: $uid) {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            _meta {
              uid
              tags
            }
            feature_image
            publication_date
            short_description
            title
            content
          }
        }
      }
    }
  }
`

const ArticleSection = styled(ContentSection)`
  .inner-container {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      display: block;

      .left-col {
        margin-right: 0;
        width: 100%;
      }

      .right-col {
        margin: 2rem 0;
        width: 100%;
      }
    }
  }

  h1,
  h2 {
    font-size: 2rem;
  }

  .left-col {
    flex: 3;
    margin-right: 2rem;
  }

  .right-col {
    flex: 1;
  }

  .meta {
    display: flex;
    align-items: center;
  }

  .date {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    font-size: 12px;
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 12px;
  }

  .main-content {
    h3 {
      font-size: 2rem;
      margin-top: 0;
      font-weight: 700;
      font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    }
  }

  .feature-image-container {
    position: relative;

    .feature-title-meta-container {
      position: absolute;
      bottom: 0;
      padding: 1rem;

      h1,
      h2,
      .date {
        color: #fff;
      }
    }
  }
`

const FeatureImage = styled("div")`
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
`

const TagColors = {
  Insights: "#0099CB",
  "Client Results": "#5DB46E",
  "@CRG": "#8CA3B5",
}

export default ({ data }) => {
  const doc = data.prismic.allNewss.edges.slice(0, 1).pop()
  if (!doc) return null
  const formattedTags = !doc.node._meta.tags
    ? []
    : doc.node._meta.tags.map(t => {
        const color = !TagColors[t] ? "#8CA3B5" : TagColors[t]
        const label = t
        return { color, label }
      })

  const formattedDate = !doc.node.publication_date
    ? undefined
    : new Date(doc.node.publication_date)

  const featureImage =
    !doc.node.feature_image || !doc.node.feature_image.url
      ? undefined
      : doc.node.feature_image.url

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.title &&
      doc.node.title[0] &&
      doc.node.title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    (doc.node &&
      doc.node.short_description &&
      doc.node.short_description[0] &&
      doc.node.short_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={featureImage}
      />
      <TopSubNav
        title="Back to News"
        titleLink="/news-and-insights"
        imageUrl={featureImage}
      />
      <ArticleSection>
        <div className="inner-container">
          <div className="left-col">
            {featureImage && (
              <div className="feature-image-container">
                <FeatureImage imageUrl={featureImage}></FeatureImage>
                <div className="feature-title-meta-container">
                  {doc.node.seo_h1 &&
                  doc.node.seo_h1[0] &&
                  doc.node.seo_h1[0].text
                    ? RichText.render(doc.node.seo_h1)
                    : RichText.render(doc.node.title)}
                  <div className="meta">
                    <span className="date">
                      {format(formattedDate, "MMMM dd, yyyy")}
                    </span>
                    {formattedTags.map(t => (
                      <span
                        className="tag"
                        style={{ backgroundColor: t.color }}
                      >
                        {t.label}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!featureImage && (
              <>
                {doc.node.seo_h1 &&
                doc.node.seo_h1[0] &&
                doc.node.seo_h1[0].text
                  ? RichText.render(doc.node.seo_h1)
                  : RichText.render(doc.node.title)}
                <div className="meta">
                  <span className="date">
                    {format(formattedDate, "MMMM dd, yyyy")}
                  </span>
                  {formattedTags.map(t => (
                    <span className="tag" style={{ backgroundColor: t.color }}>
                      {t.label}
                    </span>
                  ))}
                </div>
              </>
            )}
            <div className="main-content">
              {RichText.render(doc.node.content)}
            </div>
          </div>
          <div className="right-col">
            <RelatedArticles
              tags={doc.node._meta.tags}
              currentUid={doc.node._meta.uid}
            />
          </div>
        </div>
      </ArticleSection>
      <CallToActionContact />
    </Layout>
  )
}
