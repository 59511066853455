import React from "react"
import styled from "styled-components"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { Link, graphql, StaticQuery } from "gatsby"

export const query = graphql`
  {
    prismic {
      allNewss(sortBy: publication_date_DESC) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            feature_image
            publication_date
            short_description
            title
            content
          }
        }
      }
    }
  }
`

const Container = styled("div")`
  padding: 1rem;
  background: #fcfcfc;
  border-radius: 3px;

  .section-header {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 1rem 0;
    border-bottom: solid 1px #dddddd;

    &:last-child {
      border-bottom: none;
    }
  }

  a {
    color: #0099cb;
    text-decoration: none;
    font-size: 0.9rem;
  }
`

const renderRelatedArticles = ({ tags, currentUid }) => data => {
  const doc = data.prismic.allNewss.edges.slice(0, 1).pop()
  if (!doc) return null

  const relatedArticles = data.prismic.allNewss.edges
    .filter(
      edge =>
        edge.node._meta.uid !== currentUid &&
        (tags
          ? edge.node._meta.tags.filter(tag => tags.includes(tag)).length > 0
          : true)
    )
    .slice(0, 5)

  return (
    <Container>
      <div className="section-header">Related Articles</div>
      <ul>
        {relatedArticles.map(article => (
          <li>
            <Link to={`/news/${article.node._meta.uid}`}>
              {article.node.title[0].text}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default props => {
  return (
    <>
      <StaticQuery
        query={query}
        render={withPreview(renderRelatedArticles(props), query)}
      />
    </>
  )
}
